import React from 'react';

import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';

import { HelmetProvider } from 'react-helmet-async';
import { Store } from 'redux';

import {
  MobileAppContextValues,
  MobileAppProvider,
} from '@ha/core/MobileApp/MobileAppProvider';
import { theme } from '@hbf/dsl/legacy';

import { Config } from 'ha/config';

import { ConfigProvider } from 'ha/helpers/config';
import { IntlProvider, IntlObject } from 'ha/i18n';
import {
  CookieConsentContextType,
  CookieConsentProvider,
} from 'ha/modules/CookieConsent';
import { AppServices } from 'ha/services/getAppServices';
import { ServicesProvider } from 'ha/services/ServicesContext';

import { ErrorBoundary } from 'ha/components/ErrorBoundary';

interface Props {
  store: Store;
  config: Config;
  intl: IntlObject;
  services: AppServices;
  helmetContext?: object;
  cookieConsentCtx?: CookieConsentContextType;
  haMobileAppContext: MobileAppContextValues;
}

const AppContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  store,
  config,
  intl,
  services,
  helmetContext,
  cookieConsentCtx,
  haMobileAppContext,
  children,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider context={helmetContext}>
        <MobileAppProvider value={haMobileAppContext}>
          <Provider store={store}>
            <ConfigProvider value={config}>
              <ServicesProvider value={services}>
                <IntlProvider value={intl}>
                  <CookieConsentProvider value={cookieConsentCtx || {}}>
                    <ErrorBoundary>{children}</ErrorBoundary>
                  </CookieConsentProvider>
                </IntlProvider>
              </ServicesProvider>
            </ConfigProvider>
          </Provider>
        </MobileAppProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
};

export { AppContextProvider };
