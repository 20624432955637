import { MakeCall } from './createMakeCall';
import { GetIdentityVerificationResponse } from './types/Kyc';

const getIdentityVerificationRedirectURL =
  (makeCall: MakeCall) => (userId: 'me' | number) =>
    makeCall<GetIdentityVerificationResponse>(
      'GET',
      `/api/v2/user/${userId}/verification`,
    );

export { getIdentityVerificationRedirectURL };
