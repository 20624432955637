import qs from 'query-string';

class MobileUrlResolver {
  private baseUrl: string;

  constructor() {
    this.baseUrl = 'com.housinganywhere.app';
  }

  geBaseUrl() {
    return this.baseUrl;
  }

  getProtectedWebViewUrl({
    redirectPath = '/',
    ...queryParams
  }: {
    redirectPath: string;
    [key: string]: string | undefined;
  }) {
    return [
      `${this.baseUrl}://ha-webview`,
      qs.stringify(
        {
          redirectPath,
          ...queryParams,
        },
        {
          arrayFormat: 'comma',
          skipNull: true,
        },
      ),
    ].join('?');
  }
}

export const mobileUrlResolver = new MobileUrlResolver();
