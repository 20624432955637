import React from 'react';

import { HAMobileAppMetadata, OSType } from './types';

export type MobileAppContextValues = {
  isHAMobileApp: boolean;
} & Partial<HAMobileAppMetadata>;

const MobileAppContext = React.createContext<MobileAppContextValues>({
  isHAMobileApp: false,
  appVersion: '',
  appBuild: '',
  osType: OSType.UNKNOWN,
});

export interface MobileAppProviderProps {
  value: MobileAppContextValues;
}

export const MobileAppProvider: React.FC<
  React.PropsWithChildren<MobileAppProviderProps>
> = ({ value, children }) => {
  return (
    <MobileAppContext.Provider value={value}>
      {children}
    </MobileAppContext.Provider>
  );
};

/**
 * Prepares the mobile app context by parsing the user agent string to determine app-specific information.
 *
 * @param userAgent - Optional browser/device user agent string to parse
 *
 * @returns MobileAppContextValues object containing:
 *  - isHAMobileApp: Whether the current context is within the HA mobile app
 *  - appVersion: Version number of the HA mobile app (e.g. "1.2.3")
 *  - appBuild: Build number of the HA mobile app
 *  - osType: Operating system type (IOS, ANDROID, or UNKNOWN)
 *
 * @example
 * const context = prepareMobileAppContext(navigator.userAgent);
 * if (context.isHAMobileApp) {
 *   console.log(`Running in HA mobile app version ${context.appVersion}`);
 * }
 */
export const prepareMobileAppContext = (
  userAgent?: string,
): MobileAppContextValues => {
  const contextValues: MobileAppContextValues = {
    isHAMobileApp: false,
    appVersion: '',
    appBuild: '',
    osType: OSType.UNKNOWN,
  };

  if (userAgent) {
    contextValues.isHAMobileApp = Boolean(
      userAgent.match(/HousingAnywhereApp\/[0-9]+\.[0-9]+\.[0-9]+/),
    );
  }

  if (contextValues.isHAMobileApp && userAgent) {
    const versionMatch = userAgent.match(
      /HousingAnywhereApp\/([0-9]+\.[0-9]+\.[0-9]+)\(([0-9]+)\)/,
    );
    if (versionMatch) {
      contextValues.appVersion = versionMatch[1] || '';
      contextValues.appBuild = versionMatch[2] || '';
    }

    if (
      userAgent.toLowerCase().includes('iphone') ||
      userAgent.toLowerCase().includes('ipad')
    ) {
      contextValues.osType = OSType.IOS;
    } else if (userAgent.toLowerCase().includes('android')) {
      contextValues.osType = OSType.ANDROID;
    }
  }

  return contextValues;
};

export const useHAMobileApp = () => React.useContext(MobileAppContext);
