import { MakeCall } from './createMakeCall';
import { CreateIdentityVerificationResponse } from './types/Kyc';

const createIdentityVerificationRedirectURL =
  (makeCall: MakeCall) => (userId: 'me' | number, returnURL: string) =>
    makeCall<CreateIdentityVerificationResponse>(
      'POST',
      `/api/v2/user/${userId}/verification`,
      { data: { returnURL } },
    );

export { createIdentityVerificationRedirectURL };
